import React from 'react';
import {
    Grid, Box, Typography, Button, Container,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ComputeServiceTable from '../components/pricing/ComputeServiceTable';
import FlexibleBlockStorageSlider from '../components/pricing/FlexibleBlockStorageSlider';
import FaqTable from '../components/pricing/FaqTable';
import Layout from '../layout/Layout';
import colors from '../theme/colors';
import ObjectStoragePriceDisplay from '../components/pricing/ObjectStoragePriceDisplay';
import MonitoringPrices from '../components/pricing/MonitoringPrices';

const introductionWrapper = {
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '1440px',
    width: '100%',
    paddingBottom: '10px',
};
const introductionImageContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const columnWrapper = {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
};

const violetCenteredWrapper = {
    alignItems: 'center',
    maxWidth: '1440px',
    width: '100%',
};

const nubiComputeServiceButtonWrapper = {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
};

const flexibleBlockStorageWrapper = {
    maxWidth: '1440px',
    width: '100%',
    height: '474px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
};

const objectStorageServiceWrapper = {
    height: '403px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
};

const faqWrapper = {
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: colors.violet,
    padding: '0px 136px',
    maxWidth: '1440px',
    width: '100%',
};

const Pricing = () => {
    const [displayAllComputePrices, setDisplayAllComputePrices] = React.useState(false);
    const toggleDisplayAllComputePrices = () => {
        setDisplayAllComputePrices(!displayAllComputePrices);
    };

    const monitoringData = {
        isImage: true,
        title: 'Nubi Monitoring Service',
        customPadding: '46px 136px 60px',
        customMinHeight: '677px',
    };

    return (
        <Layout>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: colors.darkViolet,
                }}
            >
                <Box sx={{ ...introductionWrapper }}>
                    <Grid
                        container
                        sx={(theme) => ({
                            padding: '145px 136px 60px',
                            minHeight: '283px',
                            boxSizing: 'border-box',
                            [theme.breakpoints.down('md')]: {
                                padding: '109px 24px 24px',
                            },
                        })}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={8}
                            sx={(theme) => ({
                                [theme.breakpoints.down('sm')]: {
                                    textAlign: 'center',
                                },
                                ...columnWrapper,
                            })}
                        >
                            <Typography variant="sectionH2" style={{ marginBottom: '14px' }}>
                                Precios simples y transparentes.
                            </Typography>
                            <Typography variant="h3" style={{ fontWeight: 400 }}>
                                Calculá exactamente cuánto vas a pagar mensualmente.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            sm={4}
                            sx={(theme) => ({
                                [theme.breakpoints.down('sm')]: {
                                    display: 'none',
                                },
                                ...introductionImageContainer,
                            })}
                        >
                            <StaticImage src="../images/calculator_pricing.svg" alt="Calculadora" />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: colors.violet,
                    boxShadow: 'inset 0px 5px 20px 2px rgba(0, 0, 0, 0.15)',
                }}
            >
                <Box
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            padding: '48px 10px',
                        },
                        ...columnWrapper,
                        ...violetCenteredWrapper,
                        textAlign: 'center',
                        padding: '32px 136px 16px',
                    })}
                >
                    <Box style={{ width: '174px' }}>
                        <StaticImage src="../images/computer_service_282.svg" alt="Computadora" />
                    </Box>
                    <Typography variant="sectionH2" py={4} mb={2}>
                        Nubi Compute Service
                    </Typography>
                    <ComputeServiceTable open={displayAllComputePrices} />
                    <Box
                        mt={2}
                        mb={2}
                        sx={{ ...nubiComputeServiceButtonWrapper }}
                        onClick={toggleDisplayAllComputePrices}
                    >
                        <Button style={{ color: colors.green }} aria-label={displayAllComputePrices ? 'Ver menos' : 'Ver todos los planes'}>
                            {displayAllComputePrices
                                ? (<KeyboardArrowUpIcon fontSize="large" />)
                                : (<KeyboardArrowDownIcon fontSize="large" />)}
                            <Typography variant="sectionH2" style={{ fontSize: '22px', fontWeight: 700, fontFamily: 'Montserrat' }}>
                                {displayAllComputePrices ? 'Ver menos' : 'Ver todos los planes'}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: colors.darkViolet,
                }}
            >
                <Box sx={{ ...flexibleBlockStorageWrapper }}>
                    <Box style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',
                    }}
                    >
                        <Box style={{ width: '108px' }}>
                            <StaticImage src="../images/flexible-block_storage_icon.svg" alt="Almacenamiento" />
                        </Box>
                        <Typography variant="sectionH2" pt={2}>
                            Flexible Block Storage
                        </Typography>
                    </Box>
                    <FlexibleBlockStorageSlider />
                </Box>

            </Box>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: colors.violet,
                    boxShadow: 'inset 0px 5px 20px 2px rgba(0, 0, 0, 0.15)',
                }}
            >
                <Box
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            padding: '48px 0px',
                        },
                        padding: '32px 0px',
                        ...violetCenteredWrapper,
                        ...objectStorageServiceWrapper,
                    })}
                >
                    <StaticImage src="../images/object-storage_small.svg" alt="Almacenamiento" />
                    <Typography variant="sectionH2">
                        Object Storage Service
                    </Typography>
                    <ObjectStoragePriceDisplay />
                </Box>
            </Box>

            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: colors.darkViolet,
                    boxShadow: 'inset 0px 5px 20px 2px rgba(0, 0, 0, 0.15)',
                }}
            >
                <MonitoringPrices monitoringData={monitoringData} />
            </Box>

            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: colors.violet,
                }}
            >
                <Box
                    sx={(theme) => ({
                        [theme.breakpoints.down('md')]: {
                            padding: '0px 24px 24px',
                        },
                        ...faqWrapper,
                    })}
                    py={2}
                >
                    <Typography variant="sectionH2" pb={4} pt={6}>
                        Preguntas Frecuentes
                    </Typography>
                    <Box pb={4}>
                        <Container>
                            <FaqTable />
                        </Container>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
};

export default Pricing;
