import React, { useState } from 'react';
import {
    Typography, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Paper,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import colors from '../../theme/colors';

const tableContainer = {
    backgroundColor: colors.violet,
    boxShadow: 'none',
};
const arrowIcon = {
    height: '13px',
    width: '26px',
};

const collapsedTableCell = {
    paddingBottom: '0px',
    paddingTop: '8px',
    borderBottom: `5px solid ${colors.darkViolet}`,
};

const createData = (question, answer) => ({ question, answer });

const Row = (props) => {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                sx={{ '& > *': { borderBottom: 'unset' } }}
            >
                <TableCell
                    component="th"
                    scope="row"
                    sx={{ border: 'none', cursor: 'pointer' }}
                    onClick={() => setOpen(!open)}
                >
                    <Typography
                        variant="h4"
                        sx={{ fontWeight: 600, lineHeight: '30px' }}
                        style={open ? { color: colors.lightGreen } : { color: colors.cyan }}
                    >
                        {row.question}
                    </Typography>
                </TableCell>
                <TableCell sx={{ border: 'none' }}>
                    <IconButton
                        aria-label="expand row"
                        align="center"
                        size="small"
                        style={open ? { color: colors.lightGreen } : { color: colors.cyan }}
                        onClick={() => setOpen(!open)}
                        sx={{ ...arrowIcon }}
                    >
                        {open ? <KeyboardArrowUpIcon fontSize="large" /> : <KeyboardArrowDownIcon fontSize="large" />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={2} sx={{ ...collapsedTableCell }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small" aria-label="purchases">
                            <TableBody>
                                <TableRow>
                                    <TableCell scope="row" style={{ border: 'none', padding: '0px 16px 16px' }}>
                                        <Typography variant="h4" style={{ fontWeight: 500, lineHeight: '30px' }}>
                                            {row.answer}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const rows = [
    createData(
        '¿Los precios de Nubi Compute Service varían según el uso de los recursos de cómputo y tráfico de internet?',
        'No, son precios fijos que se mantienen mes a mes.',
    ),
    createData(
        '¿Se paga en dólares?',
        'No, los valores están expresados en dólares, pero el pago se realiza en tu moneda local al día de la cancelación de la factura',
    ),
    createData(
        'Medios de pago',
        'De momento ofrecemos transferencia bancaria.',
    ),
    createData(
        '¿Varía el precio de Flexible Block Storage?',
        'No, es un valor fijo para un bloque de disco fijo. No tiene costos variables.',
    ),
    createData(
        '¿El precio de Flexible Block Storage incluye backup?',
        'Sí, todos los precios incluyen un backup diario, con 5 días de retención.',
    ),
    createData(
        '¿Varía el precio Object Storage?',
        'Si. Ya que es un repositorio “infinito” de datos, cobramos únicamente por lo que estés consumiendo. Se factura la cantidad de GB consumidos al primer día del mes.',
    ),
    createData(
        '¿Existen condiciones para Nubi Migration Services?',
        'Las migraciones están bonificadas (es decir, son gratis). Sin embargo, dependiendo de la complejidad de la misma, Nubi2Go puede establecer un plazo mínimo de contratación o permanencia.',
    ),
    createData(
        '¿Subir imágenes personalizadas tiene algún costo adicional?',
        'No, el armado y/o almacenamiento de imágenes custom no posee costo adicional.',
    ),
    createData(
        '¿Cómo puedo saber el costo del servicio de CI/CD?',
        'Debido a que no hay dos equipos que trabajen igual, no poseemos un precio predeterminado. El precio lo definimos luego de entender los requerimientos. Si estás interesado en el servicio CI/CD, contactanos.',
    ),
    createData(
        '¿Los precios de Cloud Firewall and VPN varían según el uso de los recursos de cómputo y tráfico de internet?',
        'No, son precios fijos que se mantienen mes a mes.',
    ),
    createData(
        '¿Nubi Virtual Private Cloud (VPC) tiene costo?',
        'No, se pueden generar redes sin costo adicional.',
    ),
    createData(
        '¿Cómo puedo saber el costo del servicio de Dedicated Bandwidth?',
        'Debido a que existen varias alternativas de asignar ancho de banda según la necesidad, no poseemos un precio predeterminado. Si estás interesado en el servicio de Dedicated Bandwidth, contactanos.',
    ),
];

const FaqTable = () => (
    <TableContainer component={Paper} sx={{ ...tableContainer }}>
        <Table aria-label="collapsible table">
            <TableBody>
                {rows.map((row) => (
                    <Row key={row.question} row={row} />
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

export default FaqTable;
