import React from 'react';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import colors from '../../theme/colors';

const tableContainer = {
    maxWidth: '1220px',
    backgroundColor: colors.violet,
    boxShadow: 'none',
    overflowX: 'auto',
};
const titleTableCellText = {
    fontFamily: 'Montserrat',
    fontSize: '22px',
    lineHeight: '27px',
    fontWeight: 700,
};
const bodyTableCellText = {
    fontFamily: 'Montserrat',
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 600,
};
const bodyTableCellHighlight = {
    color: colors.green,
    fontWeight: 800,
};

const ssdStorageConstants = [
    { size: '40 GB', price: 4.38 },
    { size: '60 GB', price: 6.57 },
    { size: '80 GB', price: 8.76 },
    { size: '100 GB', price: 10.95 },
    { size: '120 GB', price: 13.14 },
    { size: '160 GB', price: 17.52 },
    { size: '200 GB', price: 21.9 },
    { size: '250 GB', price: 27.38 },
    { size: '300 GB', price: 32.85 },
    { size: '350 GB', price: 38.33 },
    { size: '400 GB', price: 43.8 },
];
const osConstants = [
    'Ubuntu', 'Debian', 'CentOS', 'Oracle Linux', 'Windows Server',
];
const backupConstants = [
    'Sin Backup', 'Con Backup',
];
const retentionsQuant = 5;

const createData = (name, vcpus, ram, ssd, so, backup, price) => (
    {
        name, vcpus, ram, ssd, so, backup, price,
    }
);

const Row = ({
    row, ssdStorageSelected, handleChangeSsdStorage,
    osSelected, handleChangeOs, backupSelected, handleChangeBackup,
}) => (
    <TableRow
        key={row.name}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
        <TableCell component="th" scope="row" style={{ borderBottom: `1px solid ${colors.darkViolet}` }}>
            <Typography variant="h3" style={{ ...bodyTableCellText, ...bodyTableCellHighlight }}>
                {row.name}
            </Typography>
        </TableCell>
        <TableCell style={{ borderBottom: `1px solid ${colors.darkViolet}` }}>
            <Typography variant="h3" style={{ ...bodyTableCellText }}>
                {row.vcpus}
            </Typography>
        </TableCell>
        <TableCell style={{ borderBottom: `1px solid ${colors.darkViolet}` }}>
            <Typography variant="h3" style={{ ...bodyTableCellText }}>
                {row.ram}
            </Typography>
        </TableCell>
        <TableCell style={{ borderBottom: `1px solid ${colors.darkViolet}` }}>
            <FormControl variant="standard" sx={{ m: 1 }}>
                <Select
                    labelId="ssdSelect"
                    id="ssdSelect"
                    value={ssdStorageSelected}
                    onChange={handleChangeSsdStorage}
                    disableUnderline
                    label="Ssd Storage"
                >
                    {ssdStorageConstants.map((ssd) => (
                        <MenuItem value={ssd.price} key={ssd.price}>
                            <Typography variant="h3" style={{ ...bodyTableCellText }}>
                                {ssd.size}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>
        <TableCell style={{ borderBottom: `1px solid ${colors.darkViolet}` }}>
            <FormControl
                variant="standard"
                sx={{
                    m: 1,
                    minWidth: '120px',
                }}
            >
                <Select
                    labelId="osSelect"
                    id="osSelect"
                    value={osSelected}
                    onChange={handleChangeOs}
                    disableUnderline
                    label="Os"
                >
                    {osConstants.map((os) => (
                        <MenuItem value={os} key={os}>
                            <Typography variant="h3" style={{ ...bodyTableCellText }}>
                                {os}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>
        <TableCell style={{ borderBottom: `1px solid ${colors.darkViolet}` }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: '120px' }}>
                <Select
                    labelId="backupSelect"
                    id="backupSelect"
                    value={backupSelected}
                    onChange={handleChangeBackup}
                    disableUnderline
                    label="Backup"
                >
                    {backupConstants.map((backup) => (
                        <MenuItem value={backup} key={backup}>
                            <Typography variant="h3" style={{ ...bodyTableCellText }}>
                                {backup}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </TableCell>
        <TableCell style={{ borderBottom: `1px solid ${colors.darkViolet}` }}>
            <Typography variant="h3" style={{ ...bodyTableCellText, ...bodyTableCellHighlight }}>
                USD
                {` ${row.price}`}
                /m
            </Typography>
        </TableCell>
    </TableRow>
);

const ComputeServiceTable = ({ open }) => {
    const [ssdStorageSelected, setSsdStorageSelected] = React.useState(
        ssdStorageConstants[0].price,
    );
    const handleChangeSsdStorage = (event) => {
        setSsdStorageSelected(event.target.value);
    };
    const [osSelected, setOsSelected] = React.useState(
        osConstants[0],
    );
    const handleChangeOs = (event) => {
        setOsSelected(event.target.value);
    };
    const [backupSelected, setBackupSelected] = React.useState(
        backupConstants[0],
    );

    const handleChangeBackup = (event) => {
        setBackupSelected(event.target.value);
    };

    const priceFormula = (itemPrice) => {
        let osPrice = 0;
        let backupPrice = 0;
        if (osSelected === osConstants[4]) osPrice = 296;
        if (backupSelected === backupConstants[1]) {
            backupPrice = ssdStorageSelected * 0.33 + ssdStorageSelected * retentionsQuant * 0.067;
        }
        return (itemPrice + ssdStorageSelected + osPrice + backupPrice).toFixed(2);
    };

    const rows = [
        createData('ncs.g-small1', 1, '2 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(17.68)),
        createData('ncs.g-small2', 1, '4 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(23.73)),
        createData('ncs.g-small3', 2, '4 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(35.36)),
        createData('ncs.g-medium1', 2, '6 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(41.41)),
        createData('ncs.g-medium2', 2, '8 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(47.47)),
        createData('ncs.g-medium3', 4, '8 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(70.71)),
        createData('ncs.g-medium4', 4, '12 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(82.83)),
        createData('ncs.g-large1', 4, '16 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(94.95)),
        createData('ncs.g-large2', 6, '12 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(106.07)),
        createData('ncs.g-large3', 6, '16 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(118.18)),
        createData('ncs.g-large4', 8, '16 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(141.43)),
        createData('ncs.g-large5', 8, '24 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(165.65)),
        createData('ncs.g-large6', 8, '32 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(189.88)),
        createData('ncs.g-xlarge1', 16, '32 GB', '40 GB', 'Ubuntu', 'Sin Backup', priceFormula(282.85)),
    ];

    return (
        <TableContainer component={Paper} sx={{ ...tableContainer }}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ borderBottom: `3px solid ${colors.darkViolet}` }}>
                            <Typography variant="h3" style={{ ...titleTableCellText }}>NOMBRE</Typography>
                        </TableCell>
                        <TableCell style={{ borderBottom: `3px solid ${colors.darkViolet}` }}>
                            <Typography variant="h3" style={{ ...titleTableCellText }}>VCPUS</Typography>
                        </TableCell>
                        <TableCell style={{ borderBottom: `3px solid ${colors.darkViolet}` }}>
                            <Typography variant="h3" style={{ ...titleTableCellText }}>RAM</Typography>
                        </TableCell>
                        <TableCell style={{ borderBottom: `3px solid ${colors.darkViolet}` }}>
                            <Typography variant="h3" style={{ ...titleTableCellText }}>SSD STORAGE</Typography>
                        </TableCell>
                        <TableCell style={{ borderBottom: `3px solid ${colors.darkViolet}` }}>
                            <Typography variant="h3" style={{ ...titleTableCellText }}>SO</Typography>
                        </TableCell>
                        <TableCell style={{ borderBottom: `3px solid ${colors.darkViolet}` }}>
                            <Typography variant="h3" style={{ ...titleTableCellText }}>Backup</Typography>
                        </TableCell>
                        <TableCell style={{ borderBottom: `3px solid ${colors.darkViolet}` }}>
                            <Typography variant="h3" style={{ ...titleTableCellText }}>PRECIO</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {open
                        ? rows.map((row) => (
                            <Row
                                row={row}
                                key={row.name}
                                ssdStorageSelected={ssdStorageSelected}
                                handleChangeSsdStorage={handleChangeSsdStorage}
                                osSelected={osSelected}
                                handleChangeOs={handleChangeOs}
                                backupSelected={backupSelected}
                                handleChangeBackup={handleChangeBackup}
                            />
                        ))
                        : rows.slice(0, 3).map((row) => (
                            <Row
                                row={row}
                                key={row.name}
                                ssdStorageSelected={ssdStorageSelected}
                                handleChangeSsdStorage={handleChangeSsdStorage}
                                osSelected={osSelected}
                                handleChangeOs={handleChangeOs}
                                backupSelected={backupSelected}
                                handleChangeBackup={handleChangeBackup}
                            />
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ComputeServiceTable;
